import { RouteConfig } from 'vue-router'
import auth from '@/store/modules/auth'
import split from '@/store/modules/split'
import reservation from '@/services/reservation'
import app from '@/store/modules/app'
import { SplitFeatureFlag } from '@/utils/enum'
import company from '@/store/modules/company'

export const routes: RouteConfig[] = [
  {
    path: '/',
    component: () => import('@/views/Empty.vue'),
    children: [
      {
        path: '',
        name: 'home',
        redirect: 'today',
        meta: {
          requiresAuth: true,
        },
        component: () => import('@/views/Home.vue'),
        children: [
          {
            path: 'today',
            name: 'today',
            component: () => import('@/views/Today.vue'),
          },
          {
            path: 'profile',
            name: '',
            component: () => import('@/views/Empty.vue'),
            children: [
              {
                path: '',
                name: 'profile',
                component: () => import('@/views/Profile.vue'),
              },
              {
                path: 'edit',
                name: 'profile.edit',
                component: () => import('@/views/Profile.vue'),
              },
              {
                path: 'password',
                name: 'profile.password',
                component: () => import('@/views/Profile.vue'),
              },
            ],
          },
          {
            path: 'users',
            component: () =>
              import(/* webpackChunkName: "company" */ '@/views/Empty.vue'),
            children: [
              {
                path: '',
                name: 'users',
                component: () =>
                  import(
                    /* webpackChunkName: "company-users" */ '@/views/UsersList.vue'
                  ),
              },
              {
                path: 'edit/:id',
                name: 'users.edit',
                component: () =>
                  import(
                    /* webpackChunkName: "company-users" */ '@/views/UsersDetail.vue'
                  ),
              },
              {
                path: 'view/:id',
                name: 'users.view',
                component: () =>
                  import(
                    /* webpackChunkName: "company-users" */ '@/views/UsersDetail.vue'
                  ),
              },
              {
                path: 'add',
                name: 'users.add',
                component: () =>
                  import(
                    /* webpackChunkName: "company-users" */ '@/views/UsersDetail.vue'
                  ),
              },
            ],
          },
          {
            path: 'vehicles',
            component: () =>
              import(
                /* webpackChunkName: "company-vehicles" */ '@/views/Empty.vue'
              ),
            children: [
              {
                path: '',
                name: 'vehicles',
                component: () =>
                  import(
                    /* webpackChunkName: "vehicles-list" */ '@/views/VehiclesList.vue'
                  ),
              },
              {
                path: 'add',
                name: 'vehicles.add',
                component: () =>
                  import(
                    /* webpackChunkName: "vehicles-add" */ '@/views/VehicleDetail.vue'
                  ),
              },
              {
                path: 'edit/:id',
                name: 'vehicles.edit',
                component: () =>
                  import(
                    /* webpackChunkName: "vehicles-edit" */ '@/views/VehicleDetail.vue'
                  ),
              },
              {
                path: 'view/:id',
                name: 'vehicles.view',
                component: () =>
                  import(
                    /* webpackChunkName: "vehicles-edit" */ '@/views/VehicleDetail.vue'
                  ),
              },
              {
                path: 'sync/:eldType',
                name: 'vehicles.sync',
                component: () =>
                  import(
                    /* webpackChunkName: "vehicles-sync" */ '@/views/VehicleSync.vue'
                  ),
              },
            ],
          },
          {
            path: 'garages',
            component: () =>
              import(
                /* webpackChunkName: "company-garages" */ '@/views/Empty.vue'
              ),
            children: [
              {
                path: '',
                name: 'garages',
                component: () =>
                  import(
                    /* webpackChunkName: "company-garages-list" */ '@/views/GaragesList.vue'
                  ),
              },
              {
                path: 'edit/:id',
                name: 'garages.edit',
                component: () =>
                  import(
                    /* webpackChunkName: "company-garages-detail-edit" */ '@/views/GaragesDetail.vue'
                  ),
              },
              {
                path: 'view/:id',
                name: 'garages.view',
                component: () =>
                  import(
                    /* webpackChunkName: "company-garages-detail-view" */ '@/views/GaragesDetail.vue'
                  ),
              },
              {
                path: 'add',
                name: 'garages.add',
                component: () =>
                  import(
                    /* webpackChunkName: "company-garages-detail-add" */ '@/views/GaragesDetail.vue'
                  ),
              },
            ],
          },
          {
            path: 'rates',
            component: () =>
              import(
                /* webpackChunkName: "company-rates" */ '@/views/Empty.vue'
              ),
            children: [
              {
                path: '',
                name: 'rates',
                component: () =>
                  import(
                    /* webpackChunkName: "company-garages-edit" */ '@/views/Rates.vue'
                  ),
              },
              {
                path: 'adjustments',
                component: () =>
                  import(
                    /* webpackChunkName: "company-garages-edit" */ '@/views/Empty.vue'
                  ),
                children: [
                  {
                    path: '',
                    name: 'rates.adjustments',
                    component: () =>
                      import(
                        /* webpackChunkName: "company-garages-edit" */ '@/views/Rates.vue'
                      ),
                  },
                  {
                    path: 'add',
                    name: 'rates.adjustments.add',
                    component: () =>
                      import(
                        /* webpackChunkName: "company-garages-edit" */ '@/views/Rates.vue'
                      ),
                  },
                  {
                    path: 'view/:id',
                    name: 'rates.adjustments.view',
                    component: () =>
                      import(
                        /* webpackChunkName: "company-garages-edit" */ '@/views/Rates.vue'
                      ),
                  },
                  {
                    path: 'edit/:id',
                    name: 'rates.adjustments.edit',
                    component: () =>
                      import(
                        /* webpackChunkName: "company-garages-edit" */ '@/views/Rates.vue'
                      ),
                  },
                ],
              },
              {
                path: 'minimums',
                component: () =>
                  import(
                    /* webpackChunkName: "company-rates" */ '@/views/Empty.vue'
                  ),
                children: [
                  {
                    path: '',
                    name: 'rates.minimums',
                    component: () =>
                      import(
                        /* webpackChunkName: "rates-minimums" */ '@/views/Rates.vue'
                      ),
                  },
                  {
                    path: 'add',
                    name: 'rates.minimums.add',
                    component: () =>
                      import(
                        /* webpackChunkName: "rates-minimums-add" */ '@/views/Rates.vue'
                      ),
                  },
                  {
                    path: 'view/:id',
                    name: 'rates.minimums.view',
                    component: () =>
                      import(
                        /* webpackChunkName: "rates-minimums-view" */ '@/views/Rates.vue'
                      ),
                  },
                  {
                    path: 'edit/:id',
                    name: 'rates.minimums.edit',
                    component: () =>
                      import(
                        /* webpackChunkName: "rates-minimums-edit" */ '@/views/Rates.vue'
                      ),
                  },
                ],
              },
              // {
              //   path: 'calculator',
              //   name: 'rates.calculator',
              //   component: () =>
              //     import(
              //       /* webpackChunkName: "company-garages-edit" */ '@/views/Rates.vue'
              //     ),
              // },
            ],
          },
          {
            path: 'availability',
            name: 'availability',
            component: () =>
              import(
                /* webpackChunkName: "company-availability" */ '@/views/Availability.vue'
              ),
            beforeEnter: async (to, from, next): Promise<void> => {
              if (company.hasBusifyAvailabilityIntegrationEnabled) {
                next({ name: 'home' })
              } else {
                next()
              }
            },
          },
          {
            path: 'performance',
            name: 'performance',
            redirect: 'performance/acceptance',
            component: () => import('@/views/Empty.vue'),
            beforeEnter: async (to, from, next): Promise<void> => {
              const treatment = await split.isFeatureEnabled(
                SplitFeatureFlag.OperatorPerformance
              )
              if (auth.canTransformCompany || treatment) {
                next()
              } else {
                next({ name: 'home' })
              }
            },
            children: [
              {
                path: 'acceptance',
                name: 'acceptance',
                component: () => import('@/views/Performance.vue'),
              },
              {
                path: 'trip-completion',
                name: 'trip-completion',
                component: () => import('@/views/Performance.vue'),
              },
              {
                path: 'tracking',
                name: 'tracking',
                component: () => import('@/views/Performance.vue'),
              },
              {
                path: 'on-time',
                name: 'on-time',
                component: () => import('@/views/Performance.vue'),
              },
            ],
          },
          {
            path: 'company',
            name: 'company',
            redirect: 'company/settings',
            component: () =>
              import(/* webpackChunkName: "company" */ '@/views/Empty.vue'),
            children: [
              {
                path: 'settings',
                name: '',
                component: () =>
                  import(
                    /* webpackChunkName: "company-settings" */ '@/views/Empty.vue'
                  ),
                children: [
                  {
                    path: '',
                    name: 'settings',
                    component: () => import('@/views/Company.vue'),
                  },
                  {
                    path: 'edit',
                    name: 'settings.edit',
                    component: () => import('@/views/Company.vue'),
                  },
                ],
              },
              {
                path: 'integrations',
                name: 'integrations',
                component: () => import('@/views/Company.vue'),
              },
              {
                path: 'billing',
                name: 'billing',
                component: () => import('@/views/Company.vue'),
                beforeEnter: (to, from, next): void => {
                  next()
                },
              },
              {
                path: 'website',
                name: 'website',
                component: () => import('@/views/Company.vue'),
                beforeEnter: (to, from, next): void => {
                  if (!auth.isPartner) {
                    next({ name: 'home' })
                  } else {
                    next()
                  }
                },
              },
            ],
          },
          {
            path: 'marketplace',
            name: '',
            component: () => import('@/views/Empty.vue'),
            children: [
              {
                path: '',
                name: 'marketplace',
                component: () =>
                  import(
                    /* webpackChunkName: "marketplace" */ '@/views/Marketplace.vue'
                  ),
              },
              {
                path: ':id',
                name: 'bid-detail',
                component: () =>
                  import(
                    /* webpackChunkName: "bid-detail" */ '@/views/BidDetail.vue'
                  ),
              },
            ],
          },
          {
            path: 'bookings',
            name: '',
            component: () => import('@/views/Empty.vue'),
            children: [
              {
                path: '',
                name: 'bookings',
                props: (router) => ({
                  defaultTabKey: router.params.defaultTabKey,
                }),
                component: () =>
                  import(
                    /* webpackChunkName: "bookings" */ '@/views/Bookings.vue'
                  ),
              },
              {
                path: ':id',
                name: 'booking-detail',
                component: () =>
                  import(
                    /* webpackChunkName: "booking-detail" */ '@/views/BookingDetail.vue'
                  ),
              },
              {
                path: 'opportunities/:id',
                name: 'opportunity-detail',
                component: () =>
                  import(
                    /* webpackChunkName: "opportunity-detail" */ '@/views/OpportunityDetail.vue'
                  ),
              },
            ],
          },
        ],
      },
      {
        path: 'auth-callback',
        name: 'auth-callback',
        component: () =>
          import(
            /* webpackChunkName: "auth-callback" */
            '@/views/Auth0Callback.vue'
          ),
      },
      {
        path: 'not-authorized',
        name: 'not-authorized',
        component: () =>
          import(
            /* webpackChunkName: "not-authorized" */
            '@/views/UserNotAuthorized.vue'
          ),
      },
      {
        path: '',
        name: 'preauth',
        component: () => import('@/views/Home.vue'),
        children: [
          {
            path: 'login',
            name: 'login',
            component: () =>
              import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
            beforeEnter: (to, from, next): void => {
              if (auth.isTokenSet) {
                next({ name: 'home' })
              } else {
                next()
              }
            },
          },
          {
            path: 'setPassword/:hash',
            name: 'set-password',
            component: () =>
              import(
                /* webpackChunkName: "setPassword" */ '@/views/SetPassword.vue'
              ),
            props: (router) => ({
              hash: router.params.hash,
            }),
            beforeEnter: (to, from, next): void => {
              if (auth.isTokenSet) {
                next({ name: 'home' })
              } else {
                next()
              }
            },
          },
          {
            path: '/downloadApp',
            name: 'download-app',
            component: () => import('@/views/DownloadApp.vue'),
          },
          {
            path: '/onboarding',
            name: 'onboarding',
            beforeEnter: async (to, from, next): Promise<void> => {
              if (auth.isTokenSet) {
                const response = await reservation.byHash(
                  to.query.hash as string
                )
                if (
                  response?.data?.data?.company?.companyId ===
                  auth.user.companyId
                ) {
                  next({
                    name: 'booking-detail',
                    params: { id: response.data.data.reservationId.toString() },
                  })
                } else {
                  next({ name: 'home' })
                }
              } else {
                next()
              }
            },
            component: () =>
              import(
                /* webpackChunkName: "onboarding" */ '@/views/BookingDetail.vue'
              ),
          },
          {
            path: 'forgotPassword',
            name: 'forgot-password',
            component: () =>
              import(
                /* webpackChunkName: "forgotPassword" */ '@/views/ForgotPassword.vue'
              ),
          },
          {
            path: '/*',
            name: 'not-found',
            component: () =>
              import(
                /* webpackChunkName: "not-found" */ '@/views/NotFound.vue'
              ),
          },
        ],
      },
    ],
  },
]
