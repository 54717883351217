/* eslint-disable eqeqeq */
import { Address, Reservation, Stop } from '@/models/dto'
import {
  anyNumberPattern,
  stateAbbreviationPattern,
  zipCodePattern,
} from './regex'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import { WizardAddress } from '@/models/dto/Wizard'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

/**
 *
 * Converts a string to kebab case.
 * @param string - The string to convert.
 * @returns The string in kebab case.
 */
export const toKebab = (string: string): string => {
  if (!string) {
    return null
  }
  return (string + '')
    .split('')
    .map((letter) => {
      if (/[A-Z]/.test(letter)) {
        return ` ${letter.toLowerCase()}`
      }
      return letter
    })
    .join('')
    .trim()
    .replace(/[_\s]+/g, '-')
}

/**
 *
 * Converts a string to camel case.
 * @param string - The string to convert to camel case.
 * @returns The string in camel case.
 */
export const toCamel = (string: string): string => {
  if (!string) {
    return null
  }
  return toKebab(string)
    .split('-')
    .map((word, index) => {
      if (index === 0) {
        return word
      }
      return word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase()
    })
    .join('')
}

/**
 * Converts a string to a pascal case string.
 *
 * @param string - The string to convert.
 * @returns The pascal case string.
 */
export const toPascal = (string: string): string => {
  if (!string) {
    return null
  }
  const interim = toCamel(string)
  return interim.slice(0, 1).toUpperCase() + interim.slice(1)
}

/**
 * Converts a string to a title case string.
 *
 * @param string - The string to convert.
 * @returns The title case string.
 */
export const toTitle = (string: string): string => {
  if (!string) {
    return null
  }
  return toKebab(string)
    .split('-')
    .map((word) => {
      return word.slice(0, 1).toUpperCase() + word.slice(1)
    })
    .join(' ')
}

/**
 *
 * Converts a string to sentence case.
 * @param string - The string to convert.
 * @returns The sentence-cased string.
 */
export const toSentence = (string: string): string => {
  if (!string) {
    return null
  }
  const interim = toKebab(string).replace(/-/g, ' ')
  return interim.slice(0, 1).toUpperCase() + interim.slice(1)
}

/**
 * Converts a string to snake case.
 *
 * @param string - The string to convert to snake case.
 * @returns The string in snake case.
 */
export const toSnake = (string: string): string => {
  if (!string) {
    return null
  }
  return toKebab(string).replace(/-/g, '_')
}

/**
 * Returns the given noun in plural form if the count is not 1, using the provided suffix (or 's' by default).
 * @param count - The number to check for pluralization.
 * @param noun - The noun to pluralize.
 * @param suffix - The suffix to use for pluralization (defaults to 's').
 * @returns The pluralized noun.
 */
export const pluralize = (
  count: number,
  noun: string,
  suffix = 's'
): string => {
  if (noun.endsWith('s')) {
    suffix = 'es'
  } else if (noun.endsWith('y') && count !== 1) {
    return `${noun.slice(0, -1)}ies`
  }

  return `${noun}${count !== 1 ? suffix : ''}`
}

/**
 *
 * Formats a number as a currency value with a dollar sign.
 * @param input - The number to format.
 * @returns A string representing the formatted currency value.
 */
export const currencyFilter = (input: number): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
  })
  return `${formatter.format(input)}`
}

/**
 *
 * Rounds a number to the nearest whole number and formats it as a currency value with a dollar sign.
 * @param input - The number to round and format.
 * @returns A string representing the formatted, rounded currency value.
 */
export const roundedCurrencyFilter = (input: number): string => {
  return `${currencyFilter(Math.round(input)).split('.')[0]}`
}

/**
 * Formats a number as a percentage.
 *
 * @param number - The number to format.
 * @returns A string representing the formatted percentage.
 */
export const toPercent = (number: number): string => {
  return `${number}%`
}

/**
 * Formats a phone number by adding parentheses around the area code and a dash between the prefix and line number.
 *
 * @param input - The phone number to format.
 * @returns The formatted phone number.
 */
export const phoneFormatFilter = (input: string): string => {
  if (!input || typeof input !== 'string' || input.length === 0) {
    return ''
  }
  const inp = input.replace(/[^0-9]/gi, '')

  if (input[0] === '1') {
    return `(${inp.substring(1, 4)}) ${inp.substring(4, 7)}-${inp.substring(7)}`
  }
  return `(${inp.substring(0, 3)}) ${inp.substring(3, 6)}-${inp.substring(6)}`
}

/**
 * Truncate a string to the given number of characters.
 *
 * @param str - The string to truncate.
 * @param n - The number of characters to truncate to.
 * @param useWordBoundary - Whether to truncate at a word boundary.
 * @returns The truncated string.
 */
export const truncate = (
  str: string,
  n: number,
  useWordBoundary = false
): string => {
  if (str.length <= n || !Number.isInteger(n) || n <= 0) {
    return str
  }
  const subString = str.substr(0, n - 1)
  return `${
    useWordBoundary
      ? `${subString.substr(0, subString.lastIndexOf(' '))} `
      : subString
  }...`
}

/**
 * Returns a string with the characters in the reverse order
 * @param string - The string to reverse
 * @returns the reversed string
 */
export const reverse = (string: string): string => {
  return string.split('').reverse().join('')
}

/**
 * Formats an address object into a single string.
 * The formatted address string will include the street1 and street2 fields,
 * the city, state, and postal code fields. If either street1 or street2 is not
 * present, the title field will be used in place of street1. If the title field
 * is not present or is the same as the city, it will not be included in the
 * formatted address string.
 *
 * @param address - The address object to format.
 * @returns The formatted address string.
 *
 * @example
 * import { formatStopAddress } from './formatStopAddress'
 *
 * const address = {
 *   street1: '123 Main St',
 *   city: 'New York',
 *   state: 'NY',
 *   postalCode: '10001'
 * }
 * const formattedAddress = formatStopAddress(address)
 *
 * console.log(formattedAddress)
 * // -> '123 Main St, New York, NY 10001'
 */
export const formatStopAddress = (address: Address): string => {
  const street1IsPresent = address.street1 && address.street1 !== ' '
  const street2IsPresent = address.street2 && address.street2 !== ' '

  let addressString = ''
  if (
    !street1IsPresent &&
    !street2IsPresent &&
    address.title &&
    address.title !== address.city
  ) {
    addressString = `${address.title}`
  } else {
    addressString = street1IsPresent ? `${address.street1}` : addressString
    addressString = street2IsPresent
      ? `${addressString} ${address.street2}`
      : addressString
  }
  const city = address.city || cityFromAddress(address)
  if (city) {
    addressString = addressString.length ? `${addressString},` : addressString
    addressString = `${addressString} ${city}`
  }
  addressString = address.state
    ? `${addressString}, ${address.state}`
    : addressString
  addressString = address.postalCode
    ? `${addressString} ${address.postalCode}`
    : addressString
  return addressString?.trim()
}

/**
 * Formats a timestamp and timezone into a string in the format:
 * 'MM/DD/YYYY • h:mm a z'.
 *
 * @param time - The timestamp to format.
 * @param timezone - The timezone to use for the formatted time.
 * @returns The formatted timestamp string.
 *
 * @example
 * import { formatStopTime } from './formatStopTime'
 *
 * const time = '2022-01-01T00:00:00Z'
 * const timezone = 'America/New_York'
 * const formattedTime = formatStopTime(time, timezone)
 *
 * console.log(formattedTime)
 * // -> '01/01/2022 • 7:00 pm EST'
 */
export const formatStopTime = (time: string, timezone: string): string => {
  const datetime = dayjs(time).tz(timezone)
  return `${datetime.format('MM/DD/YYYY')} • ${datetime.format('h:mm a z')}`
}

/**
 *
 * Formats an address into a pretty string that includes the street1, city, and state.
 * @param street - The string that contains the street information.
 * @param city - The string that contains the city information.
 * @param state - The string that contains the state information.
 * @returns A string with the street1, city, and state of the address.
 */
export const addressPretty = (street: string, city: string, state: string): string => {
  const parts = [street?.trim(), city?.trim(), state?.trim()].filter(Boolean) // Remove any falsy values (e.g., null, undefined, empty strings)
  return parts.join(', ')
}

/**
 * Formats the dropoff datetime of a stop into a string in the format:
 * 'MM/DD/YYYY • h:mm a z'.
 *
 * @param stop - The stop to get the dropoff datetime from.
 * @returns The formatted dropoff datetime string.
 *
 * @example
 * import { formatDropoffTime } from './formatDropoffTime'
 *
 * const stop = {
 *   dropoffDatetime: '2022-01-01T00:00:00Z',
 *   address: {
 *     timeZone: 'America/New_York'
 *   }
 * }
 * const formattedDropoffTime = formatDropoffTime(stop)
 *
 * console.log(formattedDropoffTime)
 * // -> '01/01/2022 • 7:00 pm EST'
 */
export const formatDropoffTime = (stop: Stop): string => {
  return formatStopTime(stop.dropoffDatetime, stop.address.timeZone)
}

/**
 * Formats the pickup datetime of a stop into a string in the format:
 * 'MM/DD/YYYY • h:mm a z'.
 *
 * @param stop - The stop to get the pickup datetime from.
 * @returns The formatted pickup datetime string.
 *
 * @example
 * import { formatPickupTime } from './formatPickupTime'
 *
 * const stop = {
 *   pickupDatetime: '2022-01-01T00:00:00Z',
 *   address: {
 *     timeZone: 'America/New_York'
 *   }
 * }
 * const formattedPickupTime = formatPickupTime(stop)
 *
 * console.log(formattedPickupTime)
 * // -> '01/01/2022 • 7:00 pm EST'
 */
export const formatPickupTime = (stop: Stop): string => {
  return formatStopTime(stop.pickupDatetime, stop.address.timeZone)
}

/**
 * Formats the spot time of a stop into a string in the format:
 * 'MM/DD/YYYY • h:mm a z'.
 *
 * @param stop - The stop to get the spot time from.
 * @returns The formatted spot time string.
 *
 * @example
 * import { formatSpotTime } from './formatSpotTime'
 *
 * const stop = {
 *   spotTime: {
 *     spotTime: '2022-01-01T00:00:00Z',
 *   },
 *   address: {
 *     timeZone: 'America/New_York'
 *   }
 * }
 * const formattedSpotTime = formatSpotTime(stop)
 *
 * console.log(formattedSpotTime)
 * // -> '01/01/2022 • 7:00 pm EST'
 */
export const formatSpotTime = (stop: Stop): string => {
  return formatStopTime(stop.spotTime.spotTime, stop.address.timeZone)
}

/**
 * Gets the pickup and dropoff city for a reservation.
 * The pickup city will be taken from the `pickupLocation` field, if present.
 * If `pickupLocation` is not present, the pickup city will be extracted from
 * the `firstPickupAddressName` field.
 * The dropoff city will be extracted from the `firstDropoffAddressName` field,
 * if present. If `firstDropoffAddressName` is not present, the dropoff city
 * will be the same as the pickup city.
 *
 * @param reservation - The reservation to get the pickup and dropoff cities from.
 * @returns An object containing the pickup and dropoff city.
 *
 * @example
 * import { getReservationPickupDestinationCities } from './getReservationPickupDestinationCities'
 *
 * const reservation = {
 *   pickupLocation: 'New York, NY',
 *   firstPickupAddressName: '123 Main St, New York, NY',
 *   firstDropoffAddressName: '456 Park Ave, New York, NY'
 * }
 * const cities = getReservationPickupDestinationCities(reservation)
 *
 * console.log(cities)
 * // -> { pickup: 'New York', dropoff: 'New York' }
 */
export const getReservationPickupDestinationCities = (
  reservation: Reservation
): { pickup: string; dropoff: string } => {
  let pickup = reservation.pickupLocation
    ? reservation.pickupLocation.split(',')[0]
    : cityFromAddressName(reservation.firstPickupAddressName)
  const dropoff = reservation.firstDropoffAddressName
    ? cityFromAddressName(reservation.firstDropoffAddressName)
    : pickup
  if (!pickup) {
    pickup = dropoff
  }
  return { pickup, dropoff }
}

/**
 * Formats the pickup and dropoff cities of a reservation into a string in the
 * format: 'PICKUP_CITY > DROPOFF_CITY'.
 * The pickup and dropoff cities will be extracted using the
 * `getReservationPickupDestinationCities` function.
 *
 * @param reservation - The reservation to get the pickup and dropoff cities from.
 * @returns The formatted pickup and dropoff city string.
 *
 * @example
 * import { formatReservationPickupDestinationText } from './formatReservationPickupDestinationText'
 *
 * const reservation = {
 *   pickupLocation: 'New York, NY',
 *   firstPickupAddressName: '123 Main St, New York, NY',
 *   firstDropoffAddressName: '456 Park Ave, New York, NY'
 * }
 * const pickupDestinationText = formatReservationPickupDestinationText(reservation)
 *
 * console.log(pickupDestinationText)
 * // -> 'New York > New York'
 */
export const formatReservationPickupDestinationText = (
  reservation: Reservation
): string => {
  const cities = getReservationPickupDestinationCities(reservation)
  return `${cities.pickup} > ${cities.dropoff}`
}

/**
 * Extracts the city from an address object.
 * The city will be taken from the `city` field, if present.
 * If `city` is not present, the city will be extracted from the `addressName`
 * or `name` field, if present.
 * If `addressName` or `name` is not present, the city will be the value of
 * the `title` field.
 *
 * @param address - The address object to extract the city from.
 * @returns The extracted city.
 *
 * @example
 * import { cityFromAddress } from './cityFromAddress'
 *
 * const address = {
 *   addressName: '123 Main St, New York, NY',
 *   city: 'New York'
 * }
 * const city = cityFromAddress(address)
 *
 * console.log(city)
 * // -> 'New York'
 */
export const cityFromModificationAddress = (address: WizardAddress): string => {
  if (address.city) {
    return address.city
  }
  const city = cityFromAddressName(address.name)
  if (city) {
    return city
  }
  return address.title
}

/**
 * Extracts the city from an address object.
 * The city will be taken from the `city` field, if present.
 * If `city` is not present, the city will be extracted from the `addressName`
 * or `name` field, if present.
 * If `addressName` or `name` is not present, the city will be the value of
 * the `title` field.
 *
 * @param address - The address object to extract the city from.
 * @returns The extracted city.
 *
 * @example
 * import { cityFromAddress } from './cityFromAddress'
 *
 * const address = {
 *   addressName: '123 Main St, New York, NY',
 *   city: 'New York'
 * }
 * const city = cityFromAddress(address)
 *
 * console.log(city)
 * // -> 'New York'
 */
export const cityFromAddress = (address: Address): string => {
  if (address.city) {
    return address.city
  }
  const city = cityFromAddressName(address.addressName || address.name)
  if (city) {
    return city
  }
  return address.title
}

/**
 * Returns the city from an address name.
 *
 * @param addressName - The address name to get the city from.
 * @returns The city from the address name.
 */
export const cityFromAddressName = (addressName: string): string | null => {
  if (!addressName) {
    return null
  }

  const addressNameSplit = removeZipcode(addressName)
    .replace(anyNumberPattern, '')
    .split(',')
    .map((str) => str.trim())

  const stateIndex = addressNameSplit.findIndex((string) =>
    stateAbbreviationPattern.test(string)
  )

  if (stateIndex === -1) {
    return null
  }

  return addressNameSplit[stateIndex - 1]
}

/**
 * Removes the zip code from an address.
 * The zip code will be extracted from the `addressName` field, if present.
 * @returns the address without the zip code.
 *
 * @example
 * console.log(removeZipcode('123 Main St, New York, NY 10001'))
 * // -> '123 Main St, New York, NY'
 */
export const removeZipcode = (address: string): string => {
  return address.replaceAll(new RegExp(zipCodePattern, 'g'), '')
}

/**
 * Formats a date-time string in ISO-8601 format into a date-only string
 * in the format 'MM/DD/YYYY'.
 *
 * @param datetime - The date-time string in ISO-8601 format.
 * @returns The formatted date string.
 *
 * @example
 * import { formatTimeStampDateTime } from './formatTimeStampDateTime'
 *
 * const datetime = '2022-01-01T00:00:00Z'
 * const date = formatTimeStampDateTime(datetime)
 *
 * console.log(date)
 * // -> '01/01/2022'
 */
export const formatTimeStampDateTime = (datetime: string): string => {
  if (!datetime) {
    return null
  }
  const date = dayjs(datetime.split('T')[0])
  return `${date.format('MM/DD/YYYY')}`
}

/**
 * Formats the pickup date of a reservation into a string in the format:
 * 'MMM D, YYYY\nh:mma z'.
 * The pickup date will be converted to the time zone specified in the
 * `firstPickupTimeZone` field of the reservation.
 *
 * @param reservation - The reservation to get the pickup date from.
 * @returns The formatted pickup date string.
 *
 * @example
 * import { formatReservationStartDate } from './formatReservationStartDate'
 *
 * const reservation = {
 *   pickupDate: '2022-01-01T00:00:00Z',
 *   firstPickupTimeZone: 'America/New_York'
 * }
 * const startDate = formatReservationStartDate(reservation)
 *
 * console.log(startDate)
 * // -> 'Jan 1, 2022\n7:00am EST'
 */
export const formatReservationStartDate = (
  reservation: Reservation
): string => {
  const datetime = dayjs(reservation.pickupDate).tz(
    reservation.firstPickupTimeZone
  )
  return datetime.format('MMM D, YYYY\nh:mma z')
}

const th_val = ['', 'thousand', 'million', 'billion', 'trillion']
const dg_val = [
  'zero',
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
]
const tn_val = [
  'ten',
  'eleven',
  'twelve',
  'thirteen',
  'fourteen',
  'fifteen',
  'sixteen',
  'seventeen',
  'eighteen',
  'nineteen',
]
const tw_val = [
  'twenty',
  'thirty',
  'forty',
  'fifty',
  'sixty',
  'seventy',
  'eighty',
  'ninety',
]

/**
 * Converts a number to its string representation in English.
 * This function supports numbers up to 15 digits in length, including decimal
 * numbers.
 *
 * @param number - The number to convert to a string.
 * @returns The string representation of the input number in English.
 *
 * @example
 * import { numberToString } from './numberToString'
 *
 * const number = 1234.56
 * const str = numberToString(number)
 *
 * console.log(str)
 * // -> 'one thousand two hundred thirty four point five six'
 */
export const numberToString = (number: any): string => {
  number = number.toString()
  if (detectLargeNumber(number)) {
    return 'too big'
  }
  number = number.replace(/[, ]/g, '')
  if (number != parseFloat(number)) {
    return 'not a number'
  }
  let x_val = number.indexOf('.')
  if (x_val == -1) {
    x_val = number.length
  }
  if (x_val > 15) {
    return 'too big'
  }
  const n_val = number.split('')
  let str_val = ''
  let sk_val = 0
  for (let i = 0; i < x_val; i++) {
    if ((x_val - i) % 3 == 2) {
      if (n_val[i] == '1') {
        str_val += `${tn_val[Number(n_val[i + 1])]} `
        i++
        sk_val = 1
      } else if (n_val[i] != 0) {
        str_val += `${tw_val[n_val[i] - 2]} `
        sk_val = 1
      }
    } else if (n_val[i] != 0) {
      str_val += `${dg_val[n_val[i]]} `
      if ((x_val - i) % 3 == 0) {
        str_val += 'hundred '
      }
      sk_val = 1
    }
    if ((x_val - i) % 3 == 1) {
      if (sk_val) {
        str_val += `${th_val[(x_val - i - 1) / 3]} `
      }
      sk_val = 0
    }
  }
  if (x_val != number.length) {
    const y_val = number.length
    str_val += 'point '
    for (let i = x_val + 1; i < y_val; i++) {
      str_val += `${dg_val[n_val[i]]} `
    }
  }
  return str_val?.trim()
}

/**
 * Detects if a given input string represents a large number in the form of 1e+48.
 *
 * @param input - The input string to detect
 * @returns `true` if the input string represents a large number, `false` otherwise
 */
export const detectLargeNumber = (input: string): boolean => {
  if (typeof input !== 'string') {
    return false
  }

  const match = input.match(/^[1-9]\d*e\+\d+$/i)
  return match !== null
}

/**
 * Reformats a number of minutes as 'X hr Y min'
 *
 * @param minutes - The number of minutes to convert to a formatted string
 * @returns 'X hr Y min' if minutes >= 60 and 'Y min' otherwise
 */
export const formatMinutes = (minutes: number): string => {
  // Calculate hours and remaining minutes
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60

  // Build the formatted string
  let formattedString = ''

  // Add hours part if it's greater than 0
  if (hours > 0) {
    formattedString += `${hours} hr`
  }

  // Add space if both hours and minutes are present
  if (hours > 0 && remainingMinutes > 0) {
    formattedString += ' '
  }

  // Add minutes part if it's greater than 0
  if (remainingMinutes > 0) {
    formattedString += `${remainingMinutes} min`
  }

  return formattedString
}
